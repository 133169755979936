<template>
  <div class="schedule-container" v-if="event">
    <div class="schedule">
        <div class="location" v-for="location in showLocations ? locations : []" v-bind:key="'l'+location.id" :style="{
            gridColumnStart: location.column,
            gridRowStart: firstPickableTime/unit,
            gridRowEnd: firstPickableTime/unit,
        }">
            {{location.name}}
            <Button v-if="editable" icon="edit" class="plus" small warning @onPress="_onChangeLocation(location)"/>
        </div>
        <!-- <div v-if="editable" :style="{
            gridColumnStart: locations.length+1,
            gridRowStart: firstPickableTime/unit+1,
            gridRowEnd: firstPickableTime/unit+1,
            textAlign: 'right'
        }">
            <Button icon="plus" class="plus" small primary @onPress="_onAddLocation"/>
        </div> -->
        <!-- <div v-for="location in editable ? locations : []" v-bind:key="'lp'+location.id" :style="{
            gridColumnStart: location.column,
            gridRowStart: 'auto',
            gridRowEnd: 'auto',
            verticalAlign: 'bottom'
        }">
            <Button icon="edit" class="plus" small warning @onPress="_onChangeLocation"/>
        </div> -->
        <div class="time" v-for="time in times" v-bind:key="'tb'+time.start" :style="{
            gridColumnStart: 1,
            gridColumnEnd: 1,
            gridRowStart: time.row,
            gridRowEnd: time.row + time.rowSpan,
        }">
            <span>{{time.start}}</span><span v-if="time.end" style="font-size: 0.5em">-</span><span>{{time.end}}</span>
        </div>
        <div
            class="moment"
            v-bind:class="{
                static: moment.location.capacity < 0,
                dynamic: moment.location.capacity >= 0,
                interactive: moment.interactive,
                selected: moment.selected,
            }"
            v-for="moment in moments"
            v-bind:key="'m'+moment.id"
            :style="{
                gridColumnStart: moment.column,
                gridColumnEnd: moment.location.capacity >= 0 ? moment.column : locations.length+2,
                gridRowStart: moment.row,
                gridRowEnd: moment.row+moment.rowSpan,
            }"
            @click.prevent="(e) => _onClick(moment, e)"
            v-tooltip:bottom="markdown(moment.session.description, 120)"
        >
                <Button icon="edit" class="plus fr" small warning v-if="editable" @onPress="_onChangeEvent(moment)"/>
                <Button icon="info" class="info" small v-if="!editable && moment.session.description" @onPress="_onInfo(moment)"/>
                <span class="title" v-bind:class="{
                    long: moment.session.name && moment.session.name.length > 50,
                    xlong: moment.session.name && moment.session.name.length > 70,
                }">{{moment.session.name}} <span v-if="moment.interactive && moment.required" style="color: red">*</span></span>
                <span class="tagline" v-bind:class="{
                    long: moment.session.tagline && moment.session.tagline.length > 50,
                    xlong: moment.session.tagline && moment.session.tagline.length > 150,
                }">{{moment.session.tagline}} </span>
                <span class="spots" v-if="moment.location.capacity > 0">beschikbaar: {{moment.remaining}}</span>
        </div>
    </div>
    <Modal
        v-if="openInfo"
        :visible="!!openInfo"
        :title="openInfo.session.name"
        @hidden="_onInfo"
        size="m"
    >
        <div v-html="renderMarkdown(openInfo.session.description)" />
    </Modal>
    </div>
</template>

<script>
  import { Component, Vue, Prop, Ref, Watch } from 'vue-property-decorator';
  import {markdown} from 'markdown';
  import Button from '../Button';
  import Modal from '../ModalDialog';

  @Component({
    components: {
            Button,
            Modal,
    },
  })
  export default class EventSchedule extends Vue {
    @Prop(Object) event;
        @Prop(Array) interactiveMoments;
        @Prop(Array) selectedMoments;
    @Prop(Boolean) editable;
        firstPickableTime = 0;

        columnMap = new Map();
        openInfo = null;
        locations = [];
        moments = [];
        times = [];

        renderMarkdown(md) {
            return markdown.toHTML(md);
        }

        @Watch('event', { immediate: true })
        _resetMap() {
            if(!this.event) return;
            this.columnMap = new Map();
            this.firstPickableTime = 0;
        }

        getColumn(location) {
            if(location.capacity < 0) {
                return 1;
            }
            if (!this.columnMap.has(location.id)) {
                this.columnMap.set(location.id, this.columnMap.size);
            }
            return this.columnMap.get(location.id)+1;
        }

        markdown(text, cutoff) {
            if(text && text.length > cutoff) {
                return markdown.toHTML(text.slice(0, cutoff) + '...');
            }
            return markdown.toHTML(text || '');
        }

        get unit() {
            return 15;
        }

        get infoEnabled() {
            return this.$listeners && this.$listeners.info;
        }

        getMinDuration(moment) {
            return 1; //(!this.showLocations || moment.start < this.firstPickableTime) ? 1 : 2;
        }

        @Watch('event', { immediate: true })
        _updateLocations() {
            this.locations = this.event.locations
                .filter(l => l.capacity >= 0)
                .map(l => ({
                    ...l,
                    column: 1+this.getColumn(l),
                }));
        }

        get showLocations() {
            return this.locations.some(l => l.moments[0].session.name !== l.name);
        }

        @Watch(['locations'], { immediate: true })
        @Watch('interactiveMoments')
        @Watch('selectedMoments')
        _updateMoments() {
            this.firstPickableTime = this.event.locations.flatMap(l => l.moments).filter(m => 
                m.location.capacity > 0
            ).map(m => m.start).sort().shift();
            this.moments = this.event.locations
                .flatMap(l => l.moments.map(m => ({
                    ...m,
                    id: m.id,
                    moment: m,
                    session: m.session,
                    required: m.session.required,
                    remaining: m.remaining,
                    column: 1+this.getColumn(m.location),
                    row: this.getMinDuration(m) + m.start/this.unit,
                    rowSpan: (m.session.duration/this.unit || 3),
                    selected: (this.selectedMoments||[]).includes(m.id),
                    interactive: this.interactiveMoments ? this.interactiveMoments.includes(m.id) : l.capacity >= 0,
                })));
        }

        fmtTime(ofst) {
            return new Date(new Date(this.event.date).getTime() + ofst*1000*60).toLocaleTimeString('nl-NL').slice(0,5)
        }

        @Watch('moments', { immediate: true })
        _updateTimes() {
            const result = new Map();
            this.moments.forEach(m => {
                const start = this.fmtTime(m.start);
                if(!result.has(start) || result.get(start).duration > m.session.duration) {
                    result.set(start, {
                        start,
                        duration: m.session.duration,
                        end: m.session.duration ? this.fmtTime(m.start + m.session.duration) : '',
                        row: this.getMinDuration(m) + m.start/this.unit,
                        rowSpan: m.session.duration/this.unit || 3,
                    });
                }
            });
            this.times = Array.from(result.values());
        }

        _onClick({interactive, moment}, e) {
            if(e.target && e.target.classList && e.target.classList.contains('fas')) return;
            if(interactive) {
                this.$emit('click', moment);
            }
        }

        _onAddLocation() {
            this.$emit('change', 'location');
        }

        _onAddEvent() {
            this.$emit('change', 'event');
        }

        _onChangeEvent(event) {
            this.$emit('change', 'event', event);
        }
        _onChangeLocation(location) {
            this.$emit('change', 'location', location);
        }
        _onInfo(moment) {
            if (this.infoEnabled) {
                this.$emit('info', moment);
            } else {
                this.openInfo = this.openInfo ? null : moment;
            }
        }
  }
</script>

<style type="text/css">
    .plus {
        border-radius: 100%;
        padding: 0.1rem 0.35rem;
    }

    .info {
        position: absolute;
        right: -7px;
        top: -7px;
        border-radius: 100%;
        padding: 0rem 0.45rem;
    }

    .fr {
        position: absolute;
        right: 1rem;
        top: auto;
        bottom: auto;
    }

    .schedule {
        display: grid;
        grid-auto-columns: 1fr;
        grid-template-columns: 100px;
        grid-auto-rows: 1fr;
        gap: 15px;
        text-align: center;
        margin-bottom: 1em;
    }
    .location {
        font-weight: bold;
        margin-top: auto;
    }
    .moment, .time {
        padding: 5px;
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        align-items: center;
        background-color: lightgray;
        border-radius: 5px;
        color: black;
        position: relative;
    }

    .moment .title {
        font-size: 1em;
        font-weight: bold;
    }

    .moment .tagline {
        font-size: 0.8em;
    }

    .moment .spots {
        margin-top: 1em;
        font-size: 0.6em;
    }

    .moment.static {
        background-color: #000b3b;
        color: white;
    }

    div.moment.selected {
        background-color: rgb(255, 166, 0);
        color: black;
    }

    .moment.interactive {
        background-color: #3765ff;
        cursor: pointer;
        color: white;
    }

    .moment.interactive:hover {
        filter: brightness(85%);
    }
    .moment.dynamic .title.long {
        font-size: 0.8em;
    }
    .moment.dynamic .tagline.long {
        font-size: 0.6em;
    }
    .moment.dynamic .title.long.xlong {
        font-size: 0.6em;
    }
    .moment.dynamic .tagline.long.xlong {
        font-size: 0.5em;
    }
    .moment.static .title {
        font-size: 1.3em;
    }
</style>
