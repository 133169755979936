var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.event)?_c('div',{staticClass:"schedule-container"},[_c('div',{staticClass:"schedule"},[_vm._l((_vm.showLocations ? _vm.locations : []),function(location){return _c('div',{key:'l'+location.id,staticClass:"location",style:({
          gridColumnStart: location.column,
          gridRowStart: _vm.firstPickableTime/_vm.unit,
          gridRowEnd: _vm.firstPickableTime/_vm.unit,
      })},[_vm._v(" "+_vm._s(location.name)+" "),(_vm.editable)?_c('Button',{staticClass:"plus",attrs:{"icon":"edit","small":"","warning":""},on:{"onPress":function($event){return _vm._onChangeLocation(location)}}}):_vm._e()],1)}),_vm._l((_vm.times),function(time){return _c('div',{key:'tb'+time.start,staticClass:"time",style:({
          gridColumnStart: 1,
          gridColumnEnd: 1,
          gridRowStart: time.row,
          gridRowEnd: time.row + time.rowSpan,
      })},[_c('span',[_vm._v(_vm._s(time.start))]),(time.end)?_c('span',{staticStyle:{"font-size":"0.5em"}},[_vm._v("-")]):_vm._e(),_c('span',[_vm._v(_vm._s(time.end))])])}),_vm._l((_vm.moments),function(moment){return _c('div',{directives:[{name:"tooltip",rawName:"v-tooltip:bottom",value:(_vm.markdown(moment.session.description, 120)),expression:"markdown(moment.session.description, 120)",arg:"bottom"}],key:'m'+moment.id,staticClass:"moment",class:{
              static: moment.location.capacity < 0,
              dynamic: moment.location.capacity >= 0,
              interactive: moment.interactive,
              selected: moment.selected,
          },style:({
              gridColumnStart: moment.column,
              gridColumnEnd: moment.location.capacity >= 0 ? moment.column : _vm.locations.length+2,
              gridRowStart: moment.row,
              gridRowEnd: moment.row+moment.rowSpan,
          }),on:{"click":function($event){$event.preventDefault();return (function (e) { return _vm._onClick(moment, e); }).apply(null, arguments)}}},[(_vm.editable)?_c('Button',{staticClass:"plus fr",attrs:{"icon":"edit","small":"","warning":""},on:{"onPress":function($event){return _vm._onChangeEvent(moment)}}}):_vm._e(),(!_vm.editable && moment.session.description)?_c('Button',{staticClass:"info",attrs:{"icon":"info","small":""},on:{"onPress":function($event){return _vm._onInfo(moment)}}}):_vm._e(),_c('span',{staticClass:"title",class:{
                  long: moment.session.name && moment.session.name.length > 50,
                  xlong: moment.session.name && moment.session.name.length > 70,
              }},[_vm._v(_vm._s(moment.session.name)+" "),(moment.interactive && moment.required)?_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]):_vm._e()]),_c('span',{staticClass:"tagline",class:{
                  long: moment.session.tagline && moment.session.tagline.length > 50,
                  xlong: moment.session.tagline && moment.session.tagline.length > 150,
              }},[_vm._v(_vm._s(moment.session.tagline)+" ")]),(moment.location.capacity > 0)?_c('span',{staticClass:"spots"},[_vm._v("beschikbaar: "+_vm._s(moment.remaining))]):_vm._e()],1)})],2),(_vm.openInfo)?_c('Modal',{attrs:{"visible":!!_vm.openInfo,"title":_vm.openInfo.session.name,"size":"m"},on:{"hidden":_vm._onInfo}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.renderMarkdown(_vm.openInfo.session.description))}})]):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }